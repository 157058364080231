@value desktop-s, desktop-m, min-xxlarge from "@/styles/variables.module.css";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;

  @media desktop-s {
    flex-direction: row;
    align-items: center;
    width: 65%;
  }

  @media desktop-s {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @media desktop-m {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.wrapper {
  width: 100%;
}

.select button {
  border: none;
  border-bottom: 1px solid black;
}
